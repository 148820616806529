
:root {
  --popins: "Poppins", sans-serif;
  --volkhov: "Volkhov";
  --background: #fefcfb;
  --light-gray-background: #f6f3f6;
  --light-background: #ffe7db;
  --primary-color: #e06eab;
  --secundary-color: #50446b;
  --secundary-color-transparent: rgba(80, 68, 107, 0.7);
  --title-color: #222222;
  --text-color: #666666;
  --text-color-light: #999999;
  --background-color: #faf7fc;
  --background-color-2: #fefefe;
  --background-color-confirmation: #F5F5F5;
  --line-color: #58467f;
  --success-color: #27d618;
  --error-color: #f00c0c;
  --divider-color: #ddd;
  --text-gray: #697386;
  --dots-color: #e5e5e5;
  --form-links: #8494B1;
  --form-agree-service: #0A66C2;
  --form-line-divider: #CBD4E6;
  --form-line-text: #7C8DB0;
  --form-btn: #6193D7;
  --contact-details-text: #6E7491;


  --width--container: 75%;
  --width--container-mobile: 90%;
}

$break-sm: 768px;
$break-md: 992px;
$break-lg: 1220px;
$break-xl: 1660px;

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  color: var(--title-color);
  background: var(--background);
  font-family: 'Poppins', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  padding-top: 77px;

  @media screen and (min-width: $break-lg){
    padding-top: 102px;
  }
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.titleBlack {
  color: var(--title-color);
}

.titlePink {
  display: block;
  color: var(--primary-color);
}

.container {
  width: var(--width--container-mobile);
  margin: 0 auto;
  max-width: 1150px;
   @media screen and (min-width: $break-lg){
    width: var(--width--container);
  }

  animation: fadeInAnimation ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.padding-top{
  padding-top: 50px;
  @media screen and (min-width: $break-lg){
    padding-top: 100px;
  }
}
.padding-bottom{
  padding-bottom: 50px;
  @media screen and (min-width: $break-lg){
    padding-bottom: 100px;
  }

}

a {
  color: var(--primary-color);
  font-family: var(--popins);
  font-weight: 400;

  font-size: 14px;
  line-height: 20px;
  word-spacing: 2px;
  letter-spacing: 0.2px;
  transition: 1s;

  &:hover {
    opacity: .8;
    transition: 1s;
  }
}

.btn {
  display: inline-block;
  color: white;
  width: 100%;
  border-radius: 5px;
  padding: 10px 0;
  border: 1px solid transparent;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  font-family: var(--popins);
  font-style: normal;
  font-weight: 600;
  transition: 0.15s;

  &.btn-secondary {
    background: var(--secundary-color);

    &:hover {
      background: white;
      color: var(--secundary-color);
      border: 1px solid var(--secundary-color);
      transition: 0.15s;
    }
  }
}

.btnGlobal {
  background-color: var(--primary-color);
  color: white;
  // border: none;
  border: 1px solid var(--primary-color);
  padding: 10px 54px;
  border-radius: 4px;
  transition: all 0.2s;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    background: white;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    transition: 0.15s;
  }
}

.textAfterTitle{
  width: 80%;
  margin: 30px auto 30px auto;
  text-align: center;
    p{
      margin: 0;
    }
    span{
      color: var(--primary-color);
    }

    @media screen and (min-width: $break-lg){
      margin: 50px auto 50px auto;
    }
}

.arrowSlider{
  display: block;
  background-color: transparent;
  width: 30px !important;
  height: 30px !important;
  position: absolute;
  &:hover{
    cursor: pointer;
  }
}

.loader-container {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  width: 100vw;
  height: 100vh;
  z-index: 9999999999;
}



