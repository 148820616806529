@import "@/app/styles/global.scss";

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul,
li {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--volkhov);
}

h1 {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;

  @media screen and (min-width: $break-sm) {
    font-size: 28px;
  }
  @media screen and (min-width: $break-md) {
    font-size: 35px;
  }
  @media screen and (min-width: $break-lg) {
    font-size: 45px;
  }
}

h2 {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 700;

  @media screen and (min-width: $break-sm) {
    font-size: 26px;
  }
  @media screen and (min-width: $break-md) {
    font-size: 32px;
  }
  @media screen and (min-width: $break-lg) {
    font-size: 36px;
  }
}

a {
  text-decoration: none;
  transition: .15s;

  &:hover {
    cursor: pointer;
    color: var(--primary-color);
    transition: .15s;
  }
}

p, li {
  font-family: var(--popins);
  color: var(--title-color);
  font-weight: 400;

  font-size: 14px;
  line-height: 20px;
  word-spacing: 2px;
  letter-spacing: 0.2px;

}

p {
  margin: 10px 0;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}