.footerContainer {
  a {
    color: var(--title-color);
  }
}

@media screen and (min-width: 360px){
  .footerContainer {
    background-color: var(--background-color);
    .footerTop {
      padding-bottom: 25px;
      padding-top: 30px;
      width: 90%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      a {
        line-height: 25px;
      }
      .footerLogo{
        position: relative;
        width: 67px;
        left: 50%;
        transform: translate(-50%, 0%);
        .logoImage{
          width: 57px !important;
          height: 57px !important;
        }
        .logoLink{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      .footerText {
        width: 55%;
        margin-top: 15px;
        margin-bottom: 20px;
        margin: auto;
      }
      .footerSocialNetworks {
        display: flex;
        justify-content: center;
        align-items: center;
        .oneItem {
          margin-right: 20px;
          background-color: white;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          -webkit-box-shadow: 0px 0px 12px 2px rgba(224, 224, 224, 1);
          -moz-box-shadow: 0px 0px 12px 2px rgba(224, 224, 224, 1);
          box-shadow: 0px 0px 12px 2px rgba(224, 224, 224, 1);
          position: relative;
          transition: 0.15s;
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      .footerTopMenuItems {
        display: flex;
        margin-top: 12px;
        .textTitle {
          margin-bottom: 20px;
        }
        .footerTextMenu1 {
          margin-right: 30px;
          .footerMenuItem {
            nav {
              ul {
                padding: 0;
                li {
                  list-style-type: none;
                }
              }
            }
          }
        }
      }
    }
    .footerBottom {
      width: 90%;
      margin: 0 auto;
      border-top: 1px solid rgba(153, 153, 153, 0.56);
      padding-top: 10px;
      padding-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        color: var(--text-color);
      }
      .copyright {
        display: flex;
        align-items: center;
        .text {
          margin-left: 5px;
        }
      }
    }
  }
}

@media screen and (min-width: 760px) {
  .footerContainer {
    .footerTop {
      .footerLogo {
        left: 0;
        transform: none;
      }
      .footerSocialNetworks {
        justify-content: flex-start;
      }
      .footerText {
        margin: initial;
      }
    }
    .footerBottom{
      justify-content: space-between;
    }
  }
}


@media screen and (min-width: 992px){
  .footerContainer {
    .footerTop {
      padding-bottom: 50px;
      padding-top: 60px;
      width: 70%;
      flex-direction: row;
      justify-content: space-between;
      .footerSocialNetworks {
        .oneItem {
          &:hover {
            background-color: var(--primary-color);
            cursor: pointer;
            transition: 0.15s;
          }
        }
      }
      .footerTopMenuItems {
        margin-top: 0;
        .footerTextMenu1 {
          margin-right: 60px;
        }
      }
    }
    .footerBottom {
      padding-top: 20px;
      padding-bottom: 20px;
      width: 75%;
    }
  }
}

@media screen and (min-width: 1220px){
  .footerContainer {
    .footerTop {
      .footerLogo{
        .logoImage{
          width: 67px !important;
          height: 67px !important;
        }
      }
    }
  }
}


