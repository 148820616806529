@media screen and (min-width: 360px){
  header {
    .headerMainContainer {
      display: none;
    }  
    .navItems{
      ul {
        display: flex;
        flex-direction: column;
        li {
          position: relative;
          list-style-type: none;

          a {
            color: var(--title-color);
          }

          &:hover {
            .submenu {
              ul {
                height: unset;
                padding: 15px;
                transition: .15s;
              }
            }
          }
        }

        .submenu {
          position: absolute;
          width: 200px;
          left: -15px;
          top: 20px;
          z-index: 100;

          ul {
            height: 0;
            overflow: hidden;
            padding: 0;
            display: flex;
            flex-direction: column;
            background: white;
            transition: .15s;

            li {
              margin: 2px 0;
            }
          }
        }
      }
    }
    .activeMenuItem{
      color: var(--primary-color);
    }
    .btnsContainer{
      width: 80%;
      .userEmail{
        display: flex;
        flex-direction: column;
        .userName{
          font-size: 12px;
          margin-right: 10px;
        }
      }
      .btnLoginSignUp{
        display: flex;
        flex-direction: column;
        .login {
          margin-right: 20px;
          margin-top: 7px;
          margin-bottom: 10px;
          font-size: 12px;
        }
        .btnSignUp {
          padding: 10px 45px;
          width: 25%;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (min-width: 1220px){
  header {
    .headerMainContainer {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 9999999999;
      background-color: white;
    }
    .navItems{
      ul {
        flex-direction: row;
        li {
          &:not(:last-child){
            margin-right: 20px;
          }
        }
      }
    }
    .headerContainer {
      width: 85%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 20px;
      padding-bottom: 15px;
      .headerLogo{
        position: relative;
        .logoLink{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      .headerItems {
        display: flex;
        align-items: center;
        justify-content: space-between;
        nav {
          ul {
            display: flex;
            li {
              list-style-type: none;
              &:not(:last-child){
                margin-right: 20px;
              }
            }
          }
        }
      }
      .heartCartDiv {
        margin-right: 40px;
        margin-left: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .headerCartImageDiv {
          margin-right: 20px;
        }
      }
      .btnsContainer{
        width: unset;
        margin: 0;
        .userEmail{
          flex-direction: row;
        }
        .btnLoginSignUp{
          flex-direction: row;
          align-items: center;
          .login {
            margin-top: 0;
            margin-bottom: 0;
            font-size: 14px;
          }
          .btnSignUp {
            width: unset;
          }
        }
      }
    }
  }
}




