@media screen and (min-width: 360px){
    .headerMobile{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9999999999;
        background-color: white;
        padding-top: 10px;
        padding-bottom: 10px;
        .contentHeader{
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            .logo{
                width: 57px !important;
                height: 57px !important;
                position: relative;
                .logoLink{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
            .menuMobile{
                background-color: white;
                position: absolute;
                top: 67px;
                left: -6%;
                width: 112%;
                padding: 20px 35px;
                .menuItemsContainer{
                    nav{
                        ul{
                            margin: 0;
                            padding: 0;
                            margin-bottom: 10px;
                            li{
                                margin-bottom: 5px;
                                a{
                                    font-size: 12px;
                                }

                            }
                        }
                    }
                }
            }
        }
    }
    .show{
        display: block;
    }
    .hide{
        display: none;
    }
    .heartCartDiv{
        margin-left: auto;
        margin-right: 25px;
        margin-top: 4px;
        .headerCartImageDiv{
            width: 30px;
        }
        .headerHeartImageDiv{
            // margin-left: 5px;
        }
    }
    .hamburgerMenu{
        position: relative;
        display: flex;
        flex-direction: column;
        row-gap: 3px;
        div{
            width: 30px;
            height: 3px;
            background-color: var(--primary-color);
            transition: all 0.3s;
            border-radius: 3px;
        }
    }
    .active{
        display: flex;       
    }
    .hamburgerMenu.active div:nth-child(2){
        transform: scale(0);
        opacity: 0;
    }
    .hamburgerMenu.active div:nth-child(1){
        animation: bar1Anim 0.3s ease-in-out forwards;
    }
    .hamburgerMenu.active div:nth-child(3){
        animation: bar2Anim 0.3s ease-in-out forwards;
    }
    @keyframes bar1Anim{
        50%{
            transform: translateY(8px);
        }
        100%{
            transform: translateY(8px) rotateZ(45deg);
        }
    }
    @keyframes bar2Anim{
        50%{
            transform: translateY(-4px);
        }
        100%{
            transform: translateY(-4px) rotateZ(135deg);
        }
    }
}

@media screen and (min-width: 1220px){
    .headerMobile{
       display: none;
    }
    .heartCartDiv{
        margin-left: 40px;
        margin-right: 0;
        margin-top: 0;
        .headerCartImageDiv{
            width: 30px;
        }
        .headerHeartImageDiv{
            // margin-left: 5px;
        }
    }
}

