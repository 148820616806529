.cartIconContainer {

  position: relative;
  cursor: pointer;

  .cartSize {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -4px;
    top: -4px;
    width: 18px;
    height: 18px;
    color: white;
    font-size: 12px;
    border-radius: 50%;
    background: var(--primary-color);
    font-family: var(--popins);
  }
}